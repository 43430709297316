<template>
  <vs-row>
    <vs-row vs-justify="center">
      <vs-col
        type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="12"
        vs-xs="12"
      >
        <div class="row">
          <div class="col-12">
            <div v-if="filteringData">
              <h3>Loading Data ...</h3>
              <vs-progress indeterminate color="dark">primary</vs-progress>
            </div>
            <div v-else>
              <vs-table
                :id="grid.id"
                :data="filteredData"
                :sst="true"
                @search="handleSearch"
                @change-page="handleChangePage"
                @sort="handleSort"
                :total="totalItems"
                :max-items="10"
                search
                stripe
                noDataText="No Available Data, click to Create new ones in the top right corner."
                class="text-nowrap"
              >
                <template slot="header"> </template>
                <template slot="thead">
                  <vs-th class="text-left w-30" sort-key="id">
                    Id <i class="mdi mdi-sort"></i>
                  </vs-th>
                  <vs-th class="text-left w-30" sort-key="title">
                    Title <i class="mdi mdi-sort"></i>
                  </vs-th>
                  <vs-th class="text-left w-30" sort-key="description">
                    Description <i class="mdi mdi-sort"></i>
                  </vs-th>
                  <vs-th class="text-left w-30" sort-key="releasedate">
                    Release Date <i class="mdi mdi-sort"></i>
                  </vs-th>
                  <vs-th hidden class="text-left w-30" sort-key="image">
                    Image <i class="mdi mdi-sort"></i>
                  </vs-th>
                  <vs-th hidden class="text-left w-30" sort-key="url">
                    Url <i class="mdi mdi-sort"></i>
                  </vs-th>
                  <vs-th class="text-left w-30">
                    Edit notification <i class="mdi mdi-sort"></i>
                  </vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr
                    :data="tr"
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                  >
                    <vs-td
                      :key="field"
                      class="text-left w-30"
                      v-for="(field, indextd) in data[indextr]"
                      v-bind:hidden="
                        indextd == 4 || indextd == 5 ? true : false
                      "
                    >
                      <p
                        v-if="indextd != data[indextr].length - 1"
                        v-html="field"
                      ></p>
                      <p v-if="indextd == data[indextr].length - 1">
                        <button
                          v-bind:href="field"
                          target="_blank"
                          class="btn-add"
                          @click="setForm($event, indextr)"
                          v-tooltip.bottom="{
                            content: 'Edit',
                            trigger: 'hover',
                          }"
                        >
                          <i
                            class="mdi mdi-pencil"
                            style="
                              color: blue;
                              margin-right: 15px;
                              font-size: 20px !important;
                            "
                          ></i>
                        </button>
                      </p>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
              <vs-pagination
                :total="lastPage"
                v-model="currentPage"
                @change="handleChangePage()"
              ></vs-pagination>
            </div>
          </div>
        </div>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col>
        <div class="box">
          <div class="box-header">
            <div class="response-message"></div>
          </div>
          <form
            method="post"
            enctype="multipart/form-data"
            name="adminForm"
            id="adminForm"
          >
            <div class="box-body">
              <input type="hidden" name="url" id="Url" />
              <input type="hidden" name="id" id="Id" />
              <div class="form-group">
                <label class="text-monospace text-bold text-capitalize"
                  >Target</label
                >
                <select class="form-control" name="segment" id="Segment">
                  <option>test</option>
                  <option>All</option>
                </select>
              </div>

              <div class="form-group">
                <label class="text-monospace text-bold text-capitalize"
                  >Title<span class="text-danger">*</span></label
                >
                <VueEmoji
                  :value="title"
                  width="100%"
                  name="titleTextarea"
                  @input="onInput($event, 'title')"
                  placeholder="Insert notification title Here"
                />
                <input type="hidden" name="titleOld" id="TitleOld" value="" />
                <input type="hidden" name="title" id="titleId" value="" />
                <br />
                <spam
                  id="titleTextarea_error"
                  class="b-toast-danger alert alert-danger cassiel-error"
                  style="display: none"
                ></spam>
              </div>
              <div class="form-group">
                <label class="text-monospace text-bold text-capitalize"
                  >Description<span class="text-danger">*</span></label
                >
                <VueEmoji
                  :value="desc"
                  width="100%"
                  name="descTextarea"
                  @input="onInput($event, 'desc')"
                  placeholder="Insert notification description Here"
                />
                <input type="hidden" name="desc" id="descId" value="" />
                <br />
                <spam
                  id="descTextarea_error"
                  class="b-toast-danger alert alert-danger cassiel-error"
                  style="display: none"
                ></spam>
              </div>
              <div class="form-group">
                <label class="text-monospace text-bold text-capitalize"
                  >Image</label
                >
                <input
                  type="file"
                  name="image"
                  id="Image"
                  accepts="image/*"
                  class="form-control"
                  @change="readURL()"
                  value=""
                />
                <input type="hidden" name="imageOld" id="ImageOld" value="" />
                <img
                  src=""
                  name="imageOldSrc"
                  id="ImageOldSrc"
                  class="img-rounded"
                  alt=""
                  width="100px"
                  style="margin: 10px 0"
                />
                <button
                  class="btn btn-danger btn-rounded"
                  type="button"
                  id="Remove"
                  @click="Remove"
                  style="margin: 5px 0; display: none"
                >
                  Remove Image
                </button>
              </div>
            </div>
            <div class="box-footer">
              <button
                type="submit"
                name="cassielAction"
                v-on:click.prevent="validate('save')"
                value="save"
                class="btn btn-primary btn-rounded btn-text-light-green btn-text-bold"
              >
                Save
              </button>
              <span class="cassiel-schedule" style="float: right">
                <input
                  name="scheduleTime"
                  id="scheduleTime"
                  type="datetime-local"
                />&nbsp;
                <button
                  type="submit"
                  name="cassielAction"
                  v-on:click.prevent="validate('schedule')"
                  value="schedule"
                  class="btn btn-teal btn-rounded btn-text-bold"
                >
                  Schedule
                </button>
              </span>
            </div>
          </form>
        </div>
      </vs-col>
    </vs-row>
  </vs-row>
</template>
<style>
th {
  color: white;
  padding: 6px 0 !important;
}

.vs-table--tbody-table .vs-table--thead tr {
  background: #586bb2 !important;
}

p {
  margin-bottom: 0 !important;
}

.vs-table--tbody-table .tr-values.selected {
  cursor: auto !important;
}

th.text-left.w-30:last-child i {
  display: none;
}

.btn-add {
  outline: none;
  background: transparent;
  border: none;
}

.vs-table--tbody-table .tr-values td * {
  margin-block-start: 0;
  margin-block-end: 0;
}

.emoji-wysiwyg-editor {
  border-bottom: 1px solid #008080;
  background-color: #fff;
  direction: rtl;
  padding-left: 40px;
  padding-right: 5px !important;
}

.emoji-picker-icon {
  left: 10px !important;
}

body {
  text-align: inherit !important;
}

.img-rounded {
  border-radius: 6px;
}
</style>
<script>
import VueEmoji from "emoji-vue";

export default {
  name: "cassieldatatable",
  mixins: [],
  props: {
    configuration: {
      type: [Array, Object],
    },
    maxNumber: {
      type: Number,
    },
    featuredData: {
      type: [Array, Object],
    },
  },
  data: () => ({
    grid: {
      id: "datatable",
      search: true,
    },
    filteringData: true,
    listconfiguration: [],
    filteredData: [],
    totalItems: 0,
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
    query: "",
    Orderkey: "id",
    OrderDir: "DESC",
    title: "",
    desc: "",
  }),
  mounted: function () {
    const baseListConfigurationUrl = this.$apiList.baseListConfigurationApi;
    let listConfigurationUrl =
      baseListConfigurationUrl + "/jamapp/notification";
    const token = this.$store.getters["auth/getToken"];
    let headers = {};
    if (token) {
      headers["X-AUTH-TOKEN"] = token;
    }
    this.$http({
      url: listConfigurationUrl,
      method: "GET",
      headers: headers,
    }).then((response) => {
      this.listconfiguration = response.data;
      this.filteringData = false;
      if (this.listconfiguration != undefined)
        this.Orderkey = this.listconfiguration.datatablecolumns[0].name;
      this.is_data_fetched = true;
      this.getTableData();
    });
  },
  components: {
    VueEmoji,
  },
  methods: {
    onInput: function (event, name) {
      document.getElementById(name + "Id").value = event.data;
    },
    readURL() {
      var input = document.getElementById("Image");
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          document.getElementById("ImageOldSrc").src = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        document.getElementById("Remove").style.display = "block";
      }
    },
    Remove() {
      document.getElementById("ImageOldSrc").src = "";
      document.getElementById("Image").value = "";
      document.getElementById("Remove").style.display = "none";
    },
    setForm(e, index) {
      if (e != undefined) {
        e.preventDefault();
      }
      document.getElementById("Id").value = this.filteredData[index][0];
      document.getElementById("Url").value = this.filteredData[index][5];
      this.title = this.filteredData[index][1];
      document.getElementById("TitleOld").value = this.filteredData[index][1];
      document.getElementById("titleId").value = this.filteredData[index][1];
      document.getElementById("descId").value = this.filteredData[index][2];
      this.desc = this.filteredData[index][2];
      document.getElementById("ImageOld").value = this.filteredData[index][4];
      document.getElementById("ImageOldSrc").src =
        process.env.VUE_APP_FILES_BASE_URL + this.filteredData[index][4];
      document.getElementById("Remove").style.display = "block";
    },
    handleSearch(searching) {
      this.query = searching;
      this.getTableData();
    },
    handleChangePage() {
      // this.currentPage = page;
      this.getTableData();
    },
    handleSort(key, active) {
      this.Orderkey = key;
      this.OrderDir = active;
      this.getTableData();
    },
    getTableData() {
      var formData = new FormData();
      formData.append("page", this.currentPage);
      formData.append("length", this.perPage);
      formData.append("start", (this.currentPage - 1) * this.perPage);
      formData.append("search", this.query);
      formData.append("orderKey", this.Orderkey);
      formData.append("orderDirection", this.OrderDir);
      formData.append("custom", true);
      formData.append("published", 1);

      const baseListDataApi = this.$apiList.baseListDataApi;
      let listDataUrl = baseListDataApi + "/pdf/pdf";
      const token = this.$store.getters["auth/getToken"];
      let headers = {};
      if (token) {
        headers["X-AUTH-TOKEN"] = token;
      }
      this.$http({
        url: listDataUrl,
        method: "POST",
        headers: headers,
        data: formData,
      }).then((response) => {
        this.filteredData = response.data.data;
        this.totalItems = parseInt(response.data.recordsFiltered);
        this.lastPage = parseInt(this.totalItems / this.perPage + 1);
      });
    },
    validate: function (cassielAction) {
      let formData = new FormData(document.getElementById("adminForm"));
      formData.set("cassielAction", cassielAction);
      let $ = require("jquery");
      $(".cassiel-error").css("display", "none");
      const token = this.$store.getters["auth/getToken"];
      let headers = {};
      if (token) {
        headers["X-AUTH-TOKEN"] = token;
      }

      let formSubmitUrl =
        this.$apiList.baseFormValidationApi + "/jamapp/notification";
      this.$http({
        url: formSubmitUrl,
        method: "POST",
        data: formData,
        headers: headers,
      }).then((response) => {
        console.log(response.data.error);
        if (response.data.error === "false") {
          //this.cassielid = response.data.cassielId;
          //this.shortUrl = response.data.shortUrl;
          // this.$swal('Valid');

          this.submit(cassielAction);
        }
        if (response.data.error === "true") {
          console.log(response.data.message);
          // this.$swal(response.data.message);

          var errorsArray = JSON.parse(response.data.message);
          window.scrollTo(0, 0);

          for (const [key, value] of Object.entries(errorsArray)) {
            if (key == "cassielId") {
              this.$swal(value);
            } else {
              document.getElementById(key + "_error").textContent = value;
              document.getElementById(key + "_error").style = "display:block";
            }
          }
          // $("#ErrorMessage").html("<strong>Error!</strong> "+response.data.message);
          // $("#ErrorDiv").show();
        }
      });
    },
    submit: function (cassielAction) {
      let formData = new FormData(document.getElementById("adminForm"));
      formData.set("cassielAction", cassielAction);

      const token = this.$store.getters["auth/getToken"];
      let headers = {};
      if (token) {
        headers["X-AUTH-TOKEN"] = token;
      }
      let formSubmitUrl =
        this.$apiList.baseFormSubmitAPI + "/jamapp/notification";

      this.$http({
        url: formSubmitUrl,
        method: "POST",
        data: formData,
        headers: headers,
      }).then((response) => {
        console.log(response.data.error);
        if (response.data.error === "false") {
          this.cassielid = response.data.cassielId;
          this.shortUrl = response.data.shortUrl;
          this.$swal("Saved");
        }
        if (response.data.error === "true") {
          console.log(response.data.message);
          // $("#ErrorMessage").html("<strong>Error!</strong> "+response.data.message);
          // $("#ErrorDiv").show();
        }
      });
    },
  },
};
</script>
